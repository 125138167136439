import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  return (
    browser && (
      <Layout>
        <SEO title="404: Not found" />
        <h1>404: Not Found</h1>
        <p>Uh oh, we can&apos;t seem to find the page or event in our calendar.</p>
      </Layout>
    )
  );
};

export default NotFoundPage;
